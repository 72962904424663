import type { Maybe } from '@rayova/wizara-common-types/Maybe';

export function createRegexSafe(
  regexString: Maybe<string>,
  flags?: string,
): Maybe<RegExp> {
  if (!regexString) return;

  try {
    return new RegExp(regexString, flags);
  } catch {
    //
  }
}
