import type { FC } from 'react';
import React from 'react';

import { FooterCopyright_ } from '@rayova/wizara-website/mobirise/styled/styled-footer';

export const FooterCopyright: FC = () => (
  <FooterCopyright_>
    {`Wizara AI Form Builder App © Copyright ${new Date().getFullYear()} `}
    <a href="https://www.rayova.com" rel="noopener noreferrer" target="_blank">
      Rayova
    </a>
    {' - All Rights Reserved'}

    <div>
      <OpenAI />
    </div>
  </FooterCopyright_>
);

const OpenAI: FC = () => (
  <a style={{ display: 'inline-block' }} href="/openai">
    <svg
      style={{ display: 'inline-block' }}
      aria-label="Open AI"
      width="192"
      height="90"
      viewBox="0 50 768 420"
    >
      <title>OpenAI Logo</title>
      <path
        fill="whitesmoke"
        d="M278 221c0 26 16 44 39 44s39-18 39-44-16-43-39-43-39 17-39 43zm63 0c0 18-10 31-24 31s-24-13-24-31 10-30 24-30 24 12 24 30zm73 39c16-12 16-43 0-55-10-7-25-6-33 3v-6h-14v83h14v-28c8 9 23 10 33 3zm-18-47c22 0 21 39 0 39-12 0-15-10-15-20 0-9 4-19 15-19zm95 34-12-5c-4 14-29 14-31-7h43c0-11-1-22-10-30-16-14-42-3-46 19-3 14 2 28 13 35 15 10 36 4 43-13zm-27-35c9 0 13 6 13 13h-28c2-8 8-13 15-13zm38 51h14v-36c0-17 24-19 24 0v36h14v-40c0-23-25-30-38-15v-6h-14v61zm93-84-32 84h15l7-19h36l7 19h15l-32-84h-16zm8 17 13 35h-26l13-35zm65-17h-15v84h15v-84zm-424 30c10-29-17-57-47-51a39 39 0 0 0-67 14c-30 6-43 43-22 66-10 29 17 58 47 52 20 22 58 14 67-15 30-6 42-43 22-66zm-79 77 33-19c1-1 2-2 2-5v-44l14 8v36c0 26-30 40-49 24zm-48-41 32 19h6l38-22v16l-32 18c-22 13-48-7-44-31zm11-61v37c0 3 1 4 3 5l38 22-14 8-32-19a29 29 0 0 1 5-53zm92 37-37-21 14-8 31 18c23 13 20 45-4 54v-37c0-4-1-4-4-6zm-18-38c-2-1-4 0-6 1l-37 22v-16l31-18c23-13 49 5 45 30l-33-19zm-50 47-14-8v-37c0-25 29-39 48-23l-32 19c-2 1-2 3-2 4v45zm7-16 17-10 17 10v20l-17 10-17-10v-20z"
      />
    </svg>
  </a>
);
