import type { FC } from 'react';
import React from 'react';

import { FooterCopyright } from '@rayova/wizara-website/mobirise/footer/FooterCopyright';
import { FooterLinks } from '@rayova/wizara-website/mobirise/footer/FooterLinks';
import {
  Footer_,
  FooterContainer_,
} from '@rayova/wizara-website/mobirise/styled/styled-footer';

export const Footer: FC = () => (
  <div id={Footer__wrapperId}>
    <FooterWithoutWrapper />
  </div>
);

export const FooterWithoutWrapper: FC = () => (
  <Footer_>
    <div className="wave" />
    <FooterContainer_>
      <FooterLinks />
    </FooterContainer_>
    <FooterCopyright />
  </Footer_>
);

export const Footer__wrapperId = 'wizara-footer';
