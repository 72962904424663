import type { FC, ReactElement, ReactNode } from 'react';
import React, { createElement } from 'react';

import { getFooterLinkGroupsSync } from '@rayova/wizara-website/mobirise/footer/getFooterLinkGroupsSync';
import type {
  FooterLink,
  FooterLinkGroup,
} from '@rayova/wizara-website/mobirise/footer/types/FooterLinkGroup';
import { Mobi } from '@rayova/wizara-website/mobirise/Mobi';
import {
  FooterLinks_,
  FooterLinks__Link_,
  FooterLinks__Links_,
  FooterLinks__SocialLinks_,
  FooterLinks__Title_,
} from '@rayova/wizara-website/mobirise/styled/styled-footer';

export function FooterLinks(): ReactElement {
  const [g1, g2, g3, g4, g5] = getFooterLinkGroupsSync();
  if (!g1 || !g2 || !g3 || !g4 || !g5) return <></>;

  return (
    <FooterLinks_>
      <div className="column">
        <Group group={g1} />
        <Social />
      </div>
      <div className="column">
        <Group group={g2} />
        <Group group={g3} />
      </div>
      <div className="column">
        <Group group={g4} />
      </div>
      <div className="column">
        <Group group={g5} />
      </div>
    </FooterLinks_>
  );
}

const Group: FC<{ group: FooterLinkGroup }> = ({ group: [title, links] }) => (
  <div className="group">
    <FooterLinks__Title_>{title}</FooterLinks__Title_>
    <FooterLinks__Links_>
      {links.map((link, i) => (
        <Link link={link} key={i} />
      ))}
    </FooterLinks__Links_>
  </div>
);

function Link(props: { link: FooterLink }): ReactNode {
  const [href, innerHTML, isNative, target] = props.link;

  return createElement(
    isNative ? FooterLinks__Link_ : Mobi.FooterLinks__Link_,
    {
      ...(target ? { target, rel: 'noopener noreferrer' } : {}),
      href,
      dangerouslySetInnerHTML: { __html: innerHTML },
    },
  );
}

const ProudlyCanadian: FC = () => (
  <div style={{ marginBottom: '1rem' }}>
    <em>We are proudly Canadian. </em>
    <svg
      style={{ display: 'inline-block' }}
      width="20"
      height="24"
      viewBox="0 0 448 504"
    >
      <title>Canadian Flag</title>
      <path
        fill="whitesmoke"
        d="m224 2-40 78c-2 3-6 5-9 3l-40-23 24 124c2 8-8 14-14 8l-49-54-12 30c-1 4-6 5-10 4l-63-14 22 67c1 4 0 8-4 10L4 247l111 90c2 2 4 6 3 9l-15 38 103-19c6-1 12 4 12 11l-5 70h22l-5-70c0-7 6-12 12-11l103 19-15-38c-1-3 1-7 3-9l111-90-25-12c-4-2-5-6-4-10l22-67-63 14c-4 1-9 0-10-4l-12-30-49 54c-6 6-16 0-14-8l24-124-40 23c-3 2-7 0-9-3L224 2z"
      />
    </svg>
  </div>
);

const Social: FC = () => (
  <div className="group">
    <ProudlyCanadian />
    <FooterLinks__Title_>Social</FooterLinks__Title_>
    <SocialLinks />
  </div>
);

const SocialLinks: FC = () => (
  <FooterLinks__SocialLinks_>
    <L ariaLabel="Facebook" href="https://www.facebook.com/wizaraformbuilder">
      <svg height="24" viewBox="0 4 48 48">
        <title>Facebook Logo</title>
        <path d="M18 24h-4v-7h4v-5c0-1 0-8 8-8h6v7c-6 0-6-1-6 6h6l-.5 7H26v20h-8V24z" />
      </svg>
    </L>
    <L ariaLabel="X (formerly Twitter)" href="https://twitter.com/wizaraforms">
      <svg height="24" viewBox="0 4 48 48">
        <title>X Logo</title>
        <path d="M36 4h7L29 21l17 23H32L22 29.5 9 44H2l16-19L2 4h14l9 12.5L36 4zm-2 36h4L14 8h-4l24 32z" />
      </svg>
    </L>
    <L ariaLabel="Instagram" href="https://www.instagram.com/wizaraforms">
      <svg height="24" viewBox="0 4 48 48">
        <title>Instagram Logo</title>
        <path d="M16 4C9 4 4 9 4 16v16c0 7 5 12 12 12h16c7 0 12-5 12-12V16c0-7-5-12-12-12H16zm0 36c-5 0-8-3-8-8V16c0-5 3-8 8-8h16c5 0 8 3 8 8v16c0 5-3 8-8 8H16zm8-22c3 0 6 2.5 6 6 0 3-2.5 6-6 6-3 0-6-2.7-6-6 0-3 2.5-6 6-6zm0-4a10 10 0 0 0-10 10 10 10 0 0 0 10 10 10 10 0 0 0 10-10 10 10 0 0 0-10-10zm11-2.5c1 0 2.5 1 2.5 2.5 0 1-1 2.5-2.5 2.5-1 0-2.5-1-2.5-2.5 0-1 1-2.5 2.5-2.5z" />
      </svg>
    </L>
    <L ariaLabel="Youtube" href="https://www.youtube.com/@wizaraforms">
      <svg height="24" viewBox="0 4 48 48">
        <title>Youtube Logo</title>
        <path d="M42 12C39 9 9 9 6 12c-2 2-3 21 0 24s33 3 36 0 3-21 0-24zM20 30V18l11 6-11 6z" />
      </svg>
    </L>
  </FooterLinks__SocialLinks_>
);

const L: FC<{
  ariaLabel: string;
  children?: ReactElement;
  href: string;
}> = ({ children, ariaLabel, href }) => (
  <a
    aria-label={ariaLabel}
    href={href}
    rel="noopener noreferrer"
    target="_blank"
  >
    {children}
  </a>
);
