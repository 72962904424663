import type { FC } from 'react';
import React from 'react';

import { HeaderLogo } from '@rayova/wizara-website/mobirise/header/HeaderLogo';
import { HeaderNav } from '@rayova/wizara-website/mobirise/header/HeaderNav';
import {
  Header__BuildAFormButton_,
  HeaderMenuDesktop__Container_,
  HeaderMenuDesktop__Nav_,
} from '@rayova/wizara-website/mobirise/styled/styled-header';

export const HeaderMenuDesktop: FC = () => (
  <HeaderMenuDesktop__Container_ className="HeaderMenuDesktop">
    <HeaderLogo />
    <HeaderMenuDesktop__Nav_>
      <HeaderNav />
    </HeaderMenuDesktop__Nav_>
    <div style={{ gridColumn: 3, textAlign: 'right' }}>
      <Header__BuildAFormButton_ href="https://app.wizara.com">
        Build a Form
      </Header__BuildAFormButton_>
    </div>
  </HeaderMenuDesktop__Container_>
);
