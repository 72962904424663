import type { FC, PropsWithChildren, ReactElement } from 'react';
import React, { createElement } from 'react';

import { Mobi } from '@rayova/wizara-website/mobirise/Mobi';
import {
  HeaderNav__Container_,
  HeaderNav__Link_,
} from '@rayova/wizara-website/mobirise/styled/styled-header';
import { createRegexSafe } from '@rayova/wizara-website/shared/regex';

export const HeaderNav: FC = () => (
  <HeaderNav__Container_>
    <L href="/features" native={true} label="Features" />
    <L href="/forms" native={true} label="Forms" />
    <L
      href="/form-templates"
      activeRegex="^\/form-templates"
      label="Templates"
    />
    <L href="/form-themes" activeRegex="^\/form-themes" label="Themes" />
    <L href="/pricing" native={true} label="Pricing" />
  </HeaderNav__Container_>
);

type LProps = PropsWithChildren<{
  activeRegex?: string;
  hideOnMobile?: boolean;
  href: string;
  label?: string;
  native?: boolean;
}>;

function L({
  activeRegex,
  children,
  hideOnMobile,
  href,
  label,
  native,
}: LProps): ReactElement {
  const active = isActive(Mobi.useCurrentPath(), href, activeRegex);

  return createElement(
    native ? HeaderNav__Link_ : Mobi.HeaderNav__Link_,
    { active, hideOnMobile, href },
    label || children,
  );
}

const isActive = (
  current: string,
  target: string,
  activeRegex?: string,
  activeRegexFlags = 'i',
): boolean =>
  current === target ||
  !!createRegexSafe(activeRegex, activeRegexFlags)?.exec(current);
