import type { FC } from 'react';
import React from 'react';

import { HeaderLogo } from '@rayova/wizara-website/mobirise/header/HeaderLogo';
import { HeaderNav } from '@rayova/wizara-website/mobirise/header/HeaderNav';
import {
  Header__BuildAFormButton_,
  HeaderMenuMobile__Nav_,
} from '@rayova/wizara-website/mobirise/styled/styled-header';

type Props = { open: boolean; setOpen: (value: boolean) => unknown };
export const HeaderMenuMobile: FC<Props> = ({ open, setOpen }) => (
  <div className="HeaderMenuMobile">
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <HeaderLogo />
      <button
        aria-label={open ? 'Close Menu' : 'Open Menu'}
        onClick={() => setOpen(!open)}
        type="button"
      >
        <img
          alt={open ? 'Close Menu Button' : 'Open Menu Button'}
          height="32"
          loading="lazy"
          src={open ? '/hamburger-close.svg' : '/hamburger.svg'}
          width="32"
        />
      </button>
    </div>

    {open && (
      <HeaderMenuMobile__Nav_>
        <HeaderNav />
        <Header__BuildAFormButton_ href="https://app.wizara.com">
          Build a Form
        </Header__BuildAFormButton_>
      </HeaderMenuMobile__Nav_>
    )}
  </div>
);
