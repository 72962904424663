import type { FC } from 'react';
import React, { useState } from 'react';

import { HeaderMenuDesktop } from '@rayova/wizara-website/mobirise/header/HeaderMenuDesktop';
import { HeaderMenuMobile } from '@rayova/wizara-website/mobirise/header/HeaderMenuMobile';
import { useScrollPosition } from '@rayova/wizara-website/mobirise/header/useScrollPosition';
import { Header__Container_ } from '@rayova/wizara-website/mobirise/styled/styled-header';

export const Header__wrapperId = 'wizara-header';

export const Header: FC = () => (
  <div id={Header__wrapperId}>
    <HeaderWithoutWrapper />
  </div>
);

export const HeaderWithoutWrapper: FC = () => {
  const scrollPosition = useScrollPosition();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Header__Container_ menuOpen={menuOpen} scrolling={scrollPosition > 0}>
      <HeaderMenuMobile open={menuOpen} setOpen={setMenuOpen} />
      <HeaderMenuDesktop />
    </Header__Container_>
  );
};
