import type { FC } from 'react';
import React from 'react';

import { Mobi } from '@rayova/wizara-website/mobirise/Mobi';

export const HeaderLogo: FC = () => (
  <Mobi.HeaderLogo__Link_
    href="/"
    title="Wizara Web Form Builder Service - Easily create lead generation forms"
  >
    <img alt="Wizara" height="200" src="/wizara.svg" width="960" />
  </Mobi.HeaderLogo__Link_>
);
