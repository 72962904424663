import { initMobiriseInteropReactApp } from '@rayova/wizara-website/mobirise/initMobiriseInteropReactApp';

async function initMobiriseInterop(): Promise<void> {
  try {
    initMobiriseInteropReactApp();
  } catch (error) {
    console.error(error);
  }

  return await Promise.resolve();
}

initMobiriseInterop().catch(console.error);
