export type FooterLinkGroup = [title: string, links: FooterLink[]];

export type FooterLink = [
  href: string,
  innerHTML: string,
  isNative: boolean, // i.e. is an <a> tag instead of a <Link> tag
  target?: string,
];

export const footerLinksGlobalCacheVariable = '__FooterLinksCache';
export const footerLinksGlobalCachedOnVariable = '__FooterLinksCachedOn';

export type WithFooterLinksCache = {
  [footerLinksGlobalCacheVariable]: FooterLinkGroup[];
  __FooterLinksCachedOn: number;
};
const w = (typeof window === 'undefined'
  ? global
  : window) as unknown as WithFooterLinksCache;
w[footerLinksGlobalCacheVariable] = w[footerLinksGlobalCacheVariable] || [];
w[footerLinksGlobalCachedOnVariable] =
  w[footerLinksGlobalCachedOnVariable] || 0;

export const withFooterLinksCache = w;
