import type { FooterLinkGroup } from '@rayova/wizara-website/mobirise/footer/types/FooterLinkGroup';
import { withFooterLinksCache as w } from '@rayova/wizara-website/mobirise/footer/types/FooterLinkGroup';

export const getFooterLinkGroupsSync = (): FooterLinkGroup[] =>
  w.__FooterLinksCache;

export function setFooterLinkGroupsSync(groups: FooterLinkGroup[]): void {
  w.__FooterLinksCache = groups;
  w.__FooterLinksCachedOn = Date.now();
}
