import { styled } from 'styled-components';

//
// Main <footer> element
//
export const Footer_ = styled.footer`
  background-color: #235;
  color: #fff;
  position: relative;

  .wave {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOTIwIiBoZWlnaHQ9IjEwMCI+PHBhdGggZmlsbD0iIzIzNSIgZD0iTTAgMTAwaDE5MjBWMzFjLTE4MSAzOS00MTUgNTQtNTM0IDU2LTI3NiA0LTQwMC02NC03ODktODNDNDMxLTQgMTU3LTIgMCAzMXY2OXoiLz48L3N2Zz4=);
    background-position: 100% 50%;
    background-repeat: repeat-x;
    background-size: 1920px 100px;
    height: 100px;
    left: 0;
    position: absolute;
    top: -100px;
    width: 100%;
  }
`;

//
// Utility classes
//
export const FooterContainer_ = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0.75rem;
  width: 100%;

  @media (min-width: 640px) {
    max-width: 640px;
  }
  @media (min-width: 768px) {
    max-width: 768px;
  }
  @media (min-width: 1024px) {
    max-width: 1024px;
  }
`;

//
// Links section
//
export const FooterLinks_ = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .group {
    padding: 1rem 0.75rem;
  }

  .column {
    width: 100%;
    text-align: center;

    @media (min-width: 500px) {
      width: 50%;
      text-align: left;
      font-size: 0.875rem;
    }
    @media (min-width: 768px) {
      width: auto;
      &:first-child {
        flex: 1;
      }
    }
    @media (min-width: 1024px) {
      font-size: 1rem;
    }
  }
`;
export const FooterLinks__Col_ = styled.div`
  width: 100%;
  text-align: center;

  @media (min-width: 500px) {
    width: 50%;
    text-align: left;
    font-size: 0.875rem;
  }
  @media (min-width: 768px) {
    width: auto;
    &:first-child {
      flex: 1;
    }
  }
  @media (min-width: 1024px) {
    font-size: 1rem;
  }
`;
export const FooterLinks__Title_ = styled.div`
  font-weight: bold;
  font-size: 1.125rem;
  margin-bottom: 0.75rem;
`;
export const FooterLinks__Links_ = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const FooterLinks__Link_ = styled.a`
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;

export const FooterLinks__SocialLinks_ = styled.div`
  display: flex;
  font-size: 1rem;
  gap: 0.5em;
  justify-content: center;
  flex-wrap: wrap;

  a {
    align-items: center;
    background-color: white;
    border-radius: 50%;
    display: flex;
    height: 2.5em;
    justify-content: center;
    width: 2.5em;
  }

  svg {
    position: relative;
    top: 2px;
  }

  @media (min-width: 500px) {
    justify-content: start;
  }
  @media (min-width: 768px) {
    font-size: 0.875rem;
  }
  @media (min-width: 1024px) {
    font-size: 1.125rem;
  }
`;

//
// Copyright section
//
export const FooterCopyright_ = styled.div`
  background-color: #000;
  padding-top: 2rem;
  text-align: center;

  a {
    color: #fff;
    text-decoration: underline;

    &:hover,
    &:active {
      color: #fff;
      text-decoration: none;
    }
  }
`;
