import type { ShouldForwardProp } from 'styled-components';

import type { HeaderStyledProp } from '@rayova/wizara-website/mobirise/styled/styled-header';

export const shouldForwardProp: ShouldForwardProp<'web'> = (prop) => {
  switch (prop as StyledProp) {
    case 'active':
    case 'hideOnMobile':
    case 'menuOpen':
    case 'scrolling':
      return false;

    default:
      return true;
  }
};

type StyledProp = HeaderStyledProp;
