import { FooterLinks__Link_ } from '@rayova/wizara-website/mobirise/styled/styled-footer';
import {
  HeaderLogo__Link_,
  HeaderNav__Link_,
} from '@rayova/wizara-website/mobirise/styled/styled-header';

const useCurrentPath = (): string =>
  typeof location !== 'undefined' ? location.pathname : '';
export const Mobi__original = {
  FooterLinks__Link_,
  HeaderLogo__Link_,
  HeaderNav__Link_,
  useCurrentPath,
} as const;

export const Mobi: typeof Mobi__original = { ...Mobi__original };
