import React, { StrictMode } from 'react';
import { createPortal } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { StyleSheetManager } from 'styled-components';

import type { MaybeNull } from '@rayova/wizara-common-types/Maybe';
import {
  Footer__wrapperId,
  FooterWithoutWrapper,
} from '@rayova/wizara-website/mobirise/footer/Footer';
import {
  Header__wrapperId,
  HeaderWithoutWrapper,
} from '@rayova/wizara-website/mobirise/header/Header';
import { shouldForwardProp } from '@rayova/wizara-website/mobirise/styled/shouldForwardProp';

export function initMobiriseInteropReactApp(): void {
  const $head = document.getElementsByTagName('head')[0];
  const $styles = document.getElementById('wizara-styles');
  const $root = document.getElementById('wizara-react-root');
  const $headerWrapper = document.getElementById(Header__wrapperId);
  const $footerWrapper = document.getElementById(Footer__wrapperId);

  assertExists($head, 'head element not found');
  assertExists($styles, 'styles element not found');
  assertExists($root, 'root element not found');
  assertExists($headerWrapper, 'header element not found');
  assertExists($footerWrapper, 'footer element not found');

  // remove SSR styles
  $head.removeChild($styles);

  // remove SSR elements
  $headerWrapper.innerHTML = '';
  $footerWrapper.innerHTML = '';

  // render react app where SSR elements were using portals
  const root = createRoot($root);
  root.render(
    <StrictMode>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        {createPortal(<HeaderWithoutWrapper />, $headerWrapper, 'header')}
        {createPortal(<FooterWithoutWrapper />, $footerWrapper, 'footer')}
      </StyleSheetManager>
    </StrictMode>,
  );
}

function assertExists<T>(
  value: MaybeNull<T>,
  errorMessage = 'assertExists: value is null or undefined',
): asserts value is T {
  if (value === null || value === undefined) throw new Error(errorMessage);
}
